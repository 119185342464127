<template>
  <!--
    Used to display ascreenshot taken from the relevant device.
  -->
  <div class="deviceDetailScreenshot text-center">
    <template v-if="loading">
      <LoadingPlaceholder />
    </template>
    <template v-else>
      <img
        v-if="screenshotAvailable"
        :src="pictureSrc(screenshotName, screenshot)"
        class="img-fluid"
      >
      <span v-else>{{ $t('noDataAvailable') }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: "DeviceDetailScreenshot",
  props: {
    uuid: {
      type: String,
      required: true
    },
    autoRefresh: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      screenshotAvailable: false,
      screenshot: null,
      screenshotName: null,
      refreshInterval: null,
      refreshTimeout: null
    }
  },
  watch: {
    autoRefresh (val) {
      if (val) {
        this.createRefreshInterval();
        this.refreshTimeout = window.setTimeout(() => {
          this.$emit("stopReloadingScreenshot", false);
          this.$emit("stopAutorefresh", false);
        }, 300000);
      }
      else {
        this.cancelRefreshInterval();
        window.clearTimeout(this.refreshTimeout);
      }
    }
  },
  created () {
    this.loading = true;
    this.getPhotoDetails();
  },
  beforeDestroy () {
    this.cancelRefreshInterval();
  },
  methods: {
    pictureSrc (name, image) {
      return 'data:image/jpeg;base64,' + image;
    },
    loadScreenshot () {
     if(this.screenshotAvailable) {
       this.loading = true;
       this.getScreenshot();
     }
    },
    createRefreshInterval () {
      this.refreshInterval = window.setInterval (() => {
        if(this.screenshotAvailable) {
          this.$emit("stopReloadingScreenshot", true);
          this.getScreenshot();
        }
      }, 3500 );
    },
    cancelRefreshInterval () {
      window.clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    },
    getScreenshot () {
      this.axios.get('/CentralDeviceManagement/GetScreenshot?deviceUuid=' + this.uuid)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.screenshot = response.data.base64Data;
        this.screenshotName = response.data.fileName;
      })
      .finally(() => {
        this.loading = false;
        this.$emit("stopReloadingScreenshot", false);
      });
    },
    // gets details about photos and screenshots from a device
    getPhotoDetails () {
      // checks wether the images are available
      this.axios.get('/CentralDeviceManagement/GetImageDeviceInfo?deviceUuid=' + this.uuid)
        .then((response) => {
          this.screenshotAvailable = response.data.screenshotAvailable;
          if (this.screenshotAvailable) {
            this.getScreenshot();
          } else {
            this.loading = false;
            this.$emit("stopReloadingScreenshot", false);
          }
        })
        .catch(() => {
          this.loading = false;
          this.$emit("stopReloadingScreenshot", false);
        });
    }
  }
}
</script>
